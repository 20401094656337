"use client";

import React from "react";
import isEmpty from "lodash/isEmpty";
import {
  Button,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  Image,
  Progress,
  Skeleton,
  ScrollArea,
} from "@gonoodle/gn-universe-ui";
import { Popover } from "@gonoodle/gn-universe-ui/lib/next";
import get from "lodash/get";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import {
  SECTIONS_TYPES,
  SECTIONS,
} from "@gonoodle/gn-universe-analytics-schema/src/constants";
import { twMerge } from "tailwind-merge";

import ChampChatBubble from "./ChampChatBubble";
import PointsProgressBar from "../PointsProgressBar";
import { useUser } from "../../contexts/user";
import {
  useChampionsQuery,
  useCurrentChampion,
  useProfile,
  useMonthlyBadgesQuery,
  useBadgeRulesProgress,
  useClassicBadgesQuery,
  useEarnedBadges,
} from "../../hooks";
import { useTransmogrifier } from "../../contexts/Transmogrifier";
import { useLogEvent } from "../../contexts/Analytics";
import { pauseAllJWPlayers } from "../../utils/jwplayer";
import { VIDEO_STATES, ROUTE_PREFIX, TROPHY_TYPES } from "../../constants";
import Link from "../Link";
import RedirectToLoginButton from "../RedirectToLoginButton";
import RedirectToRegistrationButton from "../RedirectToRegistrationButton";
import Badge from "../Badge";
import LottiePlayer from "../LottiePlayer";
import trophyEarnedAnimation from "../../public/lottie/notifications/trophy-earned.json";

function MonthlyChallenge() {
  const { monthlyBadges = [], isMonthlyBadgesLoading } =
    useMonthlyBadgesQuery();
  const latestBadge = React.useMemo(
    () =>
      monthlyBadges
        .filter((badge) => new Date(badge.publishStartsAt) <= new Date())
        .sort(
          (a, b) => new Date(b.publishStartsAt) - new Date(a.publishStartsAt),
        )[0],
    [monthlyBadges],
  );
  const badgeRulesProgress = useBadgeRulesProgress({ id: latestBadge?.id });

  if (isMonthlyBadgesLoading) {
    return (
      <div className="flex flex-col space-y-3 py-4 first:pt-0 last:pb-0">
        <span className="text-sm text-gray-200 font-bold">
          Monthly Challenge
        </span>

        {/* Skeleton placeholders for progress bars */}
        {Array.from({ length: 1 }).map((_, index) => (
          <div key={index} className="flex flex-col space-y-2">
            <div className="flex flex-row justify-between h-4">
              <Skeleton className="w-1/4 bg-gray-500 rounded" />

              <Skeleton className="w-1/6 bg-gray-500 rounded ml-auto" />
            </div>

            <Skeleton className="bg-gray-500 rounded-full w-full h-3">
              <div className="bg-gradient-to-br from-purple-300 to-purple-700 w-full h-3" />
            </Skeleton>
          </div>
        ))}
      </div>
    );
  }

  if (!latestBadge || !badgeRulesProgress || badgeRulesProgress.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-3 py-4 first:pt-0 last:pb-0">
      <span className="text-sm text-gray-200 font-bold">Monthly Challenge</span>

      {badgeRulesProgress.map(({ ruleId, currentScore, scoreNeeded }) => {
        const rule = latestBadge.rules.find(({ id }) => id === ruleId);

        return (
          <Progress
            key={ruleId}
            value={currentScore}
            maxValue={scoreNeeded}
            label={rule.title}
            valueLabel={`${currentScore} / ${scoreNeeded}`}
            variant={Progress.VARIANTS.DARK}
            className="text-gray-200"
          />
        );
      })}
    </div>
  );
}

function MonthlyBadges() {
  const { monthlyBadges = [], isMonthlyBadgesLoading } =
    useMonthlyBadgesQuery();

  if (isMonthlyBadgesLoading) {
    return (
      <div className="flex flex-col space-y-3 py-4 first:pt-0 last:pb-0">
        <span className="text-sm text-gray-200 font-bold">Monthly Badges</span>

        <ul className="grid grid-cols-4 gap-2 px-1">
          {Array.from({ length: 4 }).map((_, index) => (
            <li
              key={index}
              className="flex flex-col items-center space-y-[6px]"
            >
              <Skeleton className="w-[70px] h-[70px] rounded-full bg-gray-500" />

              <Skeleton className="w-[70px] h-[33px] bg-gray-500 rounded" />
            </li>
          ))}
        </ul>
      </div>
    );
  }

  if (monthlyBadges.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-3 py-4 first:pt-0 last:pb-0">
      <span className="text-sm text-gray-200 font-bold">Monthly Badges</span>

      <ul className="grid grid-cols-4 gap-2 px-1">
        {monthlyBadges.map(({ id }) => (
          <li key={id}>
            <Badge id={id} variant={Badge.VARIANTS.COMPACT} />
          </li>
        ))}
      </ul>
    </div>
  );
}

function ClassicBadges() {
  const { classicBadges = [], isClassicBadgesLoading } =
    useClassicBadgesQuery();

  if (isClassicBadgesLoading) {
    return (
      <div className="flex flex-col space-y-3 py-4 first:pt-0 last:pb-0">
        <span className="text-sm text-gray-200 font-bold">Classic Badges</span>

        <ul className="grid grid-cols-4 gap-2 px-1">
          {Array.from({ length: 4 }).map((_, index) => (
            <li
              key={index}
              className="flex flex-col items-center space-y-[6px]"
            >
              <Skeleton className="w-[70px] h-[70px] rounded-full bg-gray-500" />

              <Skeleton className="w-[70px] h-[33px] bg-gray-500 rounded" />
            </li>
          ))}
        </ul>
      </div>
    );
  }

  if (classicBadges.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-3 py-4 first:pt-0 last:pb-0">
      <span className="text-sm text-gray-200 font-bold">Classic Badges</span>

      <ul className="grid grid-cols-4 gap-2 px-1">
        {classicBadges.map(({ id }) => (
          <li key={id}>
            <Badge id={id} variant={Badge.VARIANTS.COMPACT} />
          </li>
        ))}
      </ul>
    </div>
  );
}

function BadgesQuickAccess({ playbackState }) {
  const lottiePlayerRef = React.useRef();
  const scrollContainerRef = React.useRef(null);
  const [showScrollIndicator, setShowScrollIndicator] = React.useState(false);
  const [trophyEarnedAnimationLoaded, setTrophyEarnedAnimationLoaded] =
    React.useState(false);
  const champion = useCurrentChampion();
  const { points } = useTransmogrifier();
  const { user } = useUser();
  const { earnedBadges } = useEarnedBadges();

  const showTrophyAnimation =
    !isEmpty(earnedBadges) && playbackState !== VIDEO_STATES.PLAYING;
  const showEmptyTrophy = isEmpty(earnedBadges);

  React.useEffect(() => {
    // This useEffect hook adjusts the fill color of the SVG path within the Lottie animation
    // based on the currentPill value reaching maxValue. The SVG path is targeted directly
    // via DOM manipulation because Lottie does not provide a built-in method to programmatically
    // change properties post-render. This approach requires knowing the specific structure
    // and styles of the 'trophyEarnedAnimation'.
    //
    // Important: The fill color values "#9258ff" (purple-400) for the maxValue and "#3d1687" for other values
    // are hardcoded based on the current design of the 'trophyEarnedAnimation'. If the animation
    // file changes or the design of the animation is updated, these fill values must be reviewed
    // and potentially updated to match the new animation design. Always verify that the SVG path
    // still exists and that the fill colors align with the new design aesthetics.

    if (lottiePlayerRef.current && trophyEarnedAnimationLoaded) {
      const svgPath = lottiePlayerRef.current.wrapper.querySelector("svg path");
      if (svgPath) {
        svgPath.style.transition = "fill 1s 1s";

        svgPath.style.fill =
          points >= champion.pointsPerLevel ? "#9258ff" : "#3d1687";
      }

      if (showEmptyTrophy) {
        const cupGroup = lottiePlayerRef.current.wrapper.querySelector("#cup");

        Array.from(cupGroup.children)
          .slice(0, 2) // Take only the first two children
          .forEach((child) => {
            child.setAttribute("opacity", "0"); // Set opacity to 0
          });
      }
    }
  }, [
    points,
    champion.pointsPerLevel,
    showEmptyTrophy,
    trophyEarnedAnimationLoaded,
  ]);

  React.useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const updateIndicator = () => {
      setShowScrollIndicator(container.scrollHeight > container.clientHeight);
    };

    updateIndicator(); // Initial check

    // Observe content changes inside the container (e.g., badges added/removed)
    const mutationObserver = new MutationObserver(updateIndicator);
    mutationObserver.observe(container, { childList: true, subtree: true });

    // eslint-disable-next-line consistent-return
    return () => {
      mutationObserver.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollContainerRef.current]);

  const { logEvent: logBadgesShown } = useLogEvent({
    event: "Badges Shown",
    properties: {
      sourcePage: "direct",
      sourceElement: SECTIONS.TROPHY_CUP,
      sourceName: SECTIONS_TYPES.CHAMP_PROGRESS_BAR,
    },
    options: {
      includeReferrer: false,
    },
  });

  return (
    <Popover.Root
      onOpenChange={(isOpen) => {
        if (isOpen) {
          logBadgesShown();
        }
      }}
    >
      <Popover.Trigger className="self-end h-12 w-fit outline-none translate-y-[1px] lg:-translate-x-4">
        <LottiePlayer
          ref={lottiePlayerRef}
          animationData={trophyEarnedAnimation}
          loop={showTrophyAnimation}
          play={showTrophyAnimation}
          className="h-full w-[145px]"
          onLoad={() => setTrophyEarnedAnimationLoaded(true)}
        />
      </Popover.Trigger>

      <Popover.Panel
        avoidCollisions={false} // To avoid flipping the popover when it's near the top edge of the screen.
        side="top"
        align="end"
        className="outline-none max-w-[calc(100vw-48px)] md:max-w-none z-50" // 48px is the padding of the container at small screen, and z-50 is to ensure the popover is above the nav bar.
      >
        {/* Using translate-y-8 to account for the translate of the trigger container. */}
        {/* Using translate-x-4 to account for the translate of the trigger. */}
        <ScrollArea.Panel
          ref={scrollContainerRef}
          className="relative w-full md:w-[345px] h-[420px] -translate-y-9 md:translate-y-0 lg:translate-x-4 rounded bg-black shadow-lg"
        >
          <div
            className={twMerge("py-2 px-4", !user.isLoggedIn && "h-[420px]")}
          >
            {user.isLoggedIn ? (
              <div className="flex flex-col space-y-4">
                <Popover.CloseTrigger asChild={true}>
                  <Link
                    href={{
                      pathname: `/${ROUTE_PREFIX.TROPHIES}`,
                      query: {
                        tab: TROPHY_TYPES.BADGES,
                      },
                    }}
                    className="self-end"
                    type={undefined}
                  >
                    <ExternalLinkIcon
                      width={20}
                      height={20}
                      className="text-white"
                    />
                  </Link>
                </Popover.CloseTrigger>

                <div className="flex flex-col divide-y-2 divide-gray-500">
                  <MonthlyChallenge />

                  <MonthlyBadges />

                  <ClassicBadges />
                </div>
              </div>
            ) : (
              <div className="flex flex-col h-full items-center justify-center space-y-4">
                <span className="text-sm text-gray-200 text-center font-bold">
                  Create an Account or Log in to earn badges!
                </span>

                <Popover.CloseTrigger asChild={true}>
                  <RedirectToRegistrationButton
                    className="w-full"
                    variant={BUTTON_VARIANTS.vivid}
                    size={BUTTON_SIZES.md}
                    referrer={{
                      sourceElement: SECTIONS_TYPES.CHAMP_PROGRESS_BAR,
                      sourceName: SECTIONS.CREATE_ACCOUNT_BUTTON,
                    }}
                  />
                </Popover.CloseTrigger>

                <Popover.CloseTrigger asChild={true}>
                  <RedirectToLoginButton
                    size={BUTTON_SIZES.md}
                    className="w-full"
                    referrer={{
                      sourceElement: SECTIONS_TYPES.CHAMP_PROGRESS_BAR,
                      sourceName: SECTIONS.LOGIN_BUTTON,
                    }}
                  />
                </Popover.CloseTrigger>
              </div>
            )}
          </div>

          <div
            className={twMerge(
              "sticky bottom-0 pointer-events-none w-full h-8 -mt-8 transition-opacity opacity-0 bg-gradient-to-b from-transparent to-gray-900",
              showScrollIndicator && user.isLoggedIn && "opacity-100",
            )}
          />
        </ScrollArea.Panel>
      </Popover.Panel>
    </Popover.Root>
  );
}

export default function ChampProgressBar({ videoState }) {
  const { user } = useUser();

  const champion = useCurrentChampion();
  const { champions } = useChampionsQuery();
  const { profile } = useProfile();
  const {
    points,
    earnedPoints,
    canGraduate,
    canLevelUp,
    canEarnPoints,
    canRegister,
    transitionTypes,
    transition,
    setTransition,
    levelUpChamp,
    earnPoints,
    redirectToRegistration,
  } = useTransmogrifier();

  const isLoggedOutUser = !user.isLoggedIn;
  const videoIsPaused = [VIDEO_STATES.COMPLETED, VIDEO_STATES.PAUSED].includes(
    videoState,
  );

  const phaseTransitionImage = React.useMemo(() => {
    if (!champion || !champions) return null;

    const currentChampionWithImages = champions.find(
      (champ) => champ.id === champion.champId,
    );

    if (isLoggedOutUser) {
      return get(currentChampionWithImages, "images.phase2.full", null);
    }

    return get(
      currentChampionWithImages,
      ["images", `phase${champion.level + 1}`, "full"],
      null,
    );
  }, [champion, champions, isLoggedOutUser]);

  useLogEvent({
    event: "Unregistered Champ Achievement",
    properties: {},
    options: {
      enabled:
        champion && transition === transitionTypes.pointAdded && canRegister,
      includeReferrer: false,
      includeSourcePage: false,
      includeSourcePageType: false,
    },
  });

  useLogEvent({
    event: "Reach Champ Achievement",
    properties: {
      champName: champion?.name,
      level: String(champion?.level),
    },
    options: {
      enabled:
        champion && transition === transitionTypes.pointAdded && canLevelUp,
      includeReferrer: false,
      includeSourcePage: false,
      includeSourcePageType: false,
    },
  });

  const { logEvent: logLevelUpVideoSkippedEvent } = useLogEvent({
    event: "Level Up Video Skipped",
    options: {
      includeReferrer: false,
      includeSourcePage: false,
      includeSourcePageType: false,
    },
  });

  const { logEvent: logLevelUpVideoEvent } = useLogEvent({
    event: "Level Up Video",
    options: {
      includeReferrer: false,
      includeSourcePage: false,
      includeSourcePageType: false,
    },
  });

  const handleAddPoints = () => {
    pauseAllJWPlayers();
    setTransition(transitionTypes.pointAdded);
    earnPoints();
  };

  const levelUpChampion = React.useCallback(
    (skip) => {
      if (skip === true) {
        levelUpChamp();
        logLevelUpVideoSkippedEvent({
          newChampLevel: String(champion.level + 1),
        });
      } else {
        pauseAllJWPlayers();
        setTransition(transitionTypes.levelUp);
        logLevelUpVideoEvent({ newChampLevel: String(champion.level + 1) });
      }
    },
    [
      levelUpChamp,
      logLevelUpVideoSkippedEvent,
      champion?.level,
      setTransition,
      transitionTypes.levelUp,
      logLevelUpVideoEvent,
    ],
  );

  const graduateChampion = React.useCallback(() => {
    pauseAllJWPlayers();
    setTransition(transitionTypes.graduate);
  }, [setTransition, transitionTypes.graduate]);

  if (!champion) {
    return null;
  }

  return (
    <section className="sticky bottom-0 order-last z-20 bg-black">
      <div className="container relative">
        {!transition && videoState && canEarnPoints && videoIsPaused && (
          <ChampChatBubble
            message={`You've earned ${earnedPoints} point${
              earnedPoints > 1 ? "s" : ""
            }! Watch your Champ add points to the progress bar now.`}
          >
            <Button
              className="mt-4 w-full"
              variant={BUTTON_VARIANTS.vivid}
              size={BUTTON_SIZES.md}
              onPress={handleAddPoints}
            >
              Add Point{earnedPoints > 1 ? "s" : ""}
            </Button>
          </ChampChatBubble>
        )}

        {!transition && canLevelUp && (
          <ChampChatBubble
            message={`Nice job! You got all ${champion.pointsPerLevel} points!`}
            imageSources={
              phaseTransitionImage
                ? {
                    "regular@1x": phaseTransitionImage["1x"],
                    "regular@2x": phaseTransitionImage["2x"],
                    "regular@3x": phaseTransitionImage["3x"],
                  }
                : null
            }
          >
            <Button
              className="mt-4 w-full"
              variant={BUTTON_VARIANTS.vivid}
              size={BUTTON_SIZES.md}
              onPress={levelUpChampion}
            >
              Level Up!
            </Button>

            <Button
              className="mt-4 w-full"
              variant={BUTTON_VARIANTS.darkOutline}
              size={BUTTON_SIZES.md}
              onPress={() => {
                levelUpChampion(true);
              }}
            >
              Skip
            </Button>
          </ChampChatBubble>
        )}

        {!transition && canGraduate && (
          <ChampChatBubble
            message="Congratulations! You've maxed out your Champ"
            imageSources={
              phaseTransitionImage
                ? {
                    "regular@1x": phaseTransitionImage["1x"],
                    "regular@2x": phaseTransitionImage["2x"],
                    "regular@3x": phaseTransitionImage["3x"],
                  }
                : null
            }
          >
            <Button
              className="mt-4 w-full"
              variant={BUTTON_VARIANTS.vivid}
              size={BUTTON_SIZES.md}
              onPress={graduateChampion}
            >
              Graduate Champ
            </Button>
          </ChampChatBubble>
        )}

        {!transition && canRegister && (
          <ChampChatBubble
            message="Want to continue the fun? Create your free account now to earn points, grow your Champ, and save your favorite videos!"
            imageSources={
              phaseTransitionImage
                ? {
                    "regular@1x": phaseTransitionImage["1x"],
                    "regular@2x": phaseTransitionImage["2x"],
                    "regular@3x": phaseTransitionImage["3x"],
                  }
                : null
            }
          >
            <Button
              className="mt-4 w-full"
              variant={BUTTON_VARIANTS.vivid}
              size={BUTTON_SIZES.md}
              onPress={redirectToRegistration}
            >
              Create a Free Account
            </Button>
          </ChampChatBubble>
        )}
      </div>

      <div className="container py-4 relative flex flex-col md:flex-row md:space-x-8 md:items-center md:py-0 md:static">
        <div
          className={twMerge(
            "space-x-3 flex items-center shrink-0",
            !!user.features.useBadgesProgressBar && "absolute md:static",
          )}
        >
          <div className="h-10">
            <Image
              className="w-full h-full object-contain"
              sources={{
                "regular@1x": champion.images.headshot["1x"],
                "regular@2x": champion.images.headshot["2x"],
                "regular@3x": champion.images.headshot["3x"],
              }}
              alt=""
            />
          </div>

          <div className="flex flex-col">
            {user.isLoggedIn && (
              <span className="text-xs text-purple-300 font-bold lg:text-sm">
                Level {`${champion.level}/${5}`}
              </span>
            )}

            <span className="text-xs font-bold text-white lg:text-md">
              {champion.name}
            </span>

            <span className="text-xs text-gray-200 truncate max-w-1/2 md:max-w-full">
              {isLoggedOutUser ? "Level 1" : profile.name}
            </span>
          </div>
        </div>

        <div
          className={twMerge(
            "flex flex-col w-full my-4",
            !!user.features.useBadgesProgressBar &&
              "my-0 mt-4 md:-translate-y-1/4 md:mt-0",
          )}
        >
          {user.features.useBadgesProgressBar && (
            <BadgesQuickAccess playbackState={videoState} />
          )}

          <PointsProgressBar value={points} max={champion.pointsPerLevel} />
        </div>
      </div>
    </section>
  );
}
