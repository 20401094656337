"use client";

/* eslint-disable @next/next/no-img-element */
import React from "react";
import { AgeGatedPressable } from "@gonoodle/gn-universe-ui";

import Link from "../Link";
import KidSafeSeal from "./KidSafeSeal";
import { useBeacon } from "../../contexts/Beacon";
import { socialLinks } from "../../fixtures";
import { URLS, ROUTE_PREFIX } from "../../constants";
import { useLogEvent } from "../../contexts/Analytics";
import { useRouter } from "../../hooks";

export default function Footer() {
  const router = useRouter();
  const beacon = useBeacon();
  const { logEvent: logLinkClickedEvent } = useLogEvent({
    event: "Link Clicked",
    options: {
      includeReferrer: false,
      includeSourcePage: true,
      includeSourcePageType: true,
    },
  });

  return (
    <footer aria-labelledby="footerHeading" className="gn-footer">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>

      <div className="bg-gray-900 pt-lg">
        <div className="container mx-auto md:grid md:grid-cols-6 gap-sm md:gap-lg pt-lg pb-xl">
          <div className="space-y-sm pb-md md:pb-0">
            <Link
              href={ROUTE_PREFIX.HOME}
              className="mb-sm"
              events={[
                {
                  event: "Link Clicked",
                  properties: {
                    title: "GoNoodle Logo",
                  },
                },
              ]}
            >
              <span className="sr-only">GoNoodle</span>
              <img
                className="w-36 -inset-x-sm mb-sm relative transform transition hover:scale-110 hover:-translate-y-xs"
                src={`${URLS.GN_ASSETS_BASE_URL}/web_public/GoNoodleLogo_Registered_Reverse.svg`}
                alt="GoNoodle logo"
              />
            </Link>

            <Link
              href="/company/p/who-we-are"
              className="block gn-text-sm text-gray-300 hover:text-mint"
              events={[
                {
                  event: "Link Clicked",
                  properties: {
                    title: "Who We Are",
                  },
                },
              ]}
            >
              Who We Are
            </Link>

            <Link
              href="https://www.amazon.com/stores/page/BBE94FF8-64FA-40E2-87C7-BFAFD07D26D3"
              className="block gn-text-sm text-gray-300 hover:text-mint"
              events={[
                {
                  event: "Link Clicked",
                  properties: {
                    title: "GoNoodle Store",
                  },
                },
              ]}
            >
              GoNoodle Store
            </Link>

            <Link
              href="/company"
              className="block gn-text-sm text-gray-300 hover:text-mint"
              events={[
                {
                  event: "Link Clicked",
                  properties: {
                    title: "GoNoodle Blog",
                  },
                },
              ]}
            >
              GoNoodle Blog
            </Link>

            <Link
              href="/company/press"
              className="block gn-text-sm text-gray-300 hover:text-mint"
              events={[
                {
                  event: "Link Clicked",
                  properties: {
                    title: "Press & Media",
                  },
                },
              ]}
            >
              Press &amp; Media
            </Link>

            <AgeGatedPressable
              className="text-left block gn-text-sm text-gray-300 hover:text-mint"
              onPress={() => {
                logLinkClickedEvent({
                  title: "Support Docs",
                });

                return router.push(URLS.SUPPORT_URL);
              }}
              onAbort={() => {
                router.replace(ROUTE_PREFIX.HOME);
              }}
            >
              Support Docs
            </AgeGatedPressable>

            <AgeGatedPressable
              className="text-left block gn-text-sm text-gray-300 hover:text-mint"
              onPress={() => beacon.open()}
              onAbort={() => {
                router.replace(ROUTE_PREFIX.HOME);
              }}
            >
              Contact Support
            </AgeGatedPressable>
          </div>

          <div className="space-y-sm pb-md md:pb-0">
            <p className="text-md md:text-sm lg:text-md text-white uppercase font-bold mb-sm">
              SuperNoodle
            </p>

            <AgeGatedPressable
              className="block text-left gn-text-sm text-gray-300 hover:text-mint"
              onPress={() => {
                logLinkClickedEvent({
                  title: "SuperNoodle Logo",
                });

                return router.push(URLS.SUPERNOODLE_URL);
              }}
              onAbort={() => {
                router.replace(ROUTE_PREFIX.HOME);
              }}
            >
              About SuperNoodle
            </AgeGatedPressable>

            <Link
              href="/company/p/ctac-supernoodle"
              className="block gn-text-sm text-gray-300 hover:text-mint"
              events={[
                {
                  event: "Link Clicked",
                  properties: {
                    title: "SuperNoodle Customer Terms and Conditions",
                  },
                },
              ]}
            >
              SuperNoodle Customer Terms and Conditions
            </Link>

            <Link
              href="/company/p/tos-supernoodle"
              className="block gn-text-sm text-gray-300 hover:text-mint"
              events={[
                {
                  event: "Link Clicked",
                  properties: {
                    title: "SuperNoodle Terms of Service",
                  },
                },
              ]}
            >
              SuperNoodle Terms of Service
            </Link>
          </div>

          <div className="space-y-sm">
            <p className="text-md md:text-sm lg:text-md text-white uppercase font-bold mb-sm">
              Join the Movement!
            </p>

            <Link
              href="https://app.trinethire.com/companies/470380-gonoodle-inc/jobs"
              className="block gn-text-sm text-gray-300 hover:text-mint"
              events={[
                {
                  event: "Link Clicked",
                  properties: {
                    title: "Careers at GoNoodle",
                  },
                },
              ]}
            >
              Careers at GoNoodle
            </Link>

            <Link
              href="/company/p/contact-us"
              className="block gn-text-sm text-gray-300 hover:text-mint"
              events={[
                {
                  event: "Link Clicked",
                  properties: {
                    title: "Contact Us",
                  },
                },
              ]}
            >
              Contact Us
            </Link>

            <AgeGatedPressable
              className="block gn-text-sm text-gray-300 hover:text-mint"
              onPress={() => {
                logLinkClickedEvent({
                  title: "Partner With Us",
                });

                router.push(URLS.PARTNER_WITH_US_URL);
              }}
              onAbort={() => {
                router.replace(ROUTE_PREFIX.HOME);
              }}
            >
              Partner With Us
            </AgeGatedPressable>
          </div>

          <div className="hidden lg:block">&nbsp;</div>

          <div className="col-span-3 lg:col-span-2 flex flex-col space-y-md mt-lg md:mt-0">
            <div>
              <p className="gn-text-sm text-white mb-sm">
                GoNoodle Video Apps are available for FREE!
              </p>

              <div className="flex flex-wrap">
                <Link
                  href="https://apps.apple.com/app/gonoodle-kids-videos/id1050712293"
                  className="block mr-sm mb-sm"
                  events={[
                    {
                      event: "Link Clicked",
                      properties: {
                        title:
                          "Download GoNoodle Video App on the Apple App Store",
                      },
                    },
                  ]}
                >
                  <span className="sr-only">
                    Download GoNoodle Video App on the Apple App Store
                  </span>
                  <img
                    src={`${URLS.GN_ASSETS_BASE_URL}/web_public/badge-apple-appStore.svg`}
                    alt="Apple App Store Badge"
                  />
                </Link>

                <Link
                  href="https://itunes.apple.com/us/app/gonoodle/id1050712293?mt=8&utm_campaign=Homepage&utm_medium=website&utm_source=gonoodle"
                  className="block mr-sm mb-sm"
                  events={[
                    {
                      event: "Link Clicked",
                      properties: {
                        title: "Download on Apple TV",
                      },
                    },
                  ]}
                >
                  <span className="sr-only">Download on Apple TV</span>
                  <img
                    src={`${URLS.GN_ASSETS_BASE_URL}/web_public/badge-appleTv.svg`}
                    alt="Apple TV Badge"
                  />
                </Link>

                <Link
                  href="https://play.google.com/store/apps/details?id=com.gonoodle.gonoodle"
                  className="block mr-sm mb-sm"
                  events={[
                    {
                      event: "Link Clicked",
                      properties: {
                        title: "Download Video App on Google Play",
                      },
                    },
                  ]}
                >
                  <span className="sr-only">Download on Google Play</span>
                  <img
                    src={`${URLS.GN_ASSETS_BASE_URL}/web_public/badge-playStore.svg`}
                    alt="Google Play Badge"
                  />
                </Link>

                <Link
                  href="https://channelstore.roku.com/details/09361a3e2de66cc65c3cb1fdf4d7127a/gonoodle"
                  className="block mr-sm mb-sm"
                  events={[
                    {
                      event: "Link Clicked",
                      properties: {
                        title: "Roku Players and TV",
                      },
                    },
                  ]}
                >
                  <span className="sr-only">
                    Available on Roku Players and TV
                  </span>
                  <img
                    src={`${URLS.GN_ASSETS_BASE_URL}/web_public/badge-roku.svg`}
                    alt="Roku Badge"
                  />
                </Link>

                <Link
                  href="https://www.amazon.com/GoNoodle-Kids-Videos/dp/B07Y2J5V13"
                  className="block mr-sm mb-sm"
                  events={[
                    {
                      event: "Link Clicked",
                      properties: {
                        title: "Amazon App Store",
                      },
                    },
                  ]}
                >
                  <span className="sr-only">Available on Amazon App Store</span>
                  <img
                    src={`${URLS.GN_ASSETS_BASE_URL}/web_public/badge-amazon.svg`}
                    alt="Amazon App Store"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-800">
        <div className="container mx-auto flex flex-col md:flex-row space-y-sm md:space-y-0 justify-between items-center py-md">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <KidSafeSeal />
            <p className="block gn-text-xs text-white text-center px-xl md:px-0 md:text-left pb-md md:pb-0">
              Made with <span className="text-yellow">joy</span> for kids and
              the grownups who love them ❤️
            </p>
          </div>

          <div className="space-x-sm justify-end flex">
            {socialLinks.map((item) => (
              <Link
                key={item.name}
                href={item.href}
                className="text-white transition opacity-75 hover:opacity-100"
                events={[
                  {
                    event: "Link Clicked",
                    properties: {
                      title: item.name,
                    },
                  },
                ]}
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-gray-900">
        <div className="container mx-auto flex flex-col md:flex-row justify-between items-center py-sm">
          <div className="flex flex-row flex-wrap md:flex-nowrap justify-center">
            <Link
              href="/company/p/privacy-policy"
              className="block gn-text-xs font-bold text-white hover:text-yellow px-sm py-xs md:py-0"
              events={[
                {
                  event: "Link Clicked",
                  properties: {
                    title: "Privacy Policy",
                  },
                },
              ]}
            >
              Privacy Policy
            </Link>

            <Link
              href="/company/p/tos"
              className="block gn-text-xs text-white hover:text-yellow px-sm py-xs md:py-0"
              events={[
                {
                  event: "Link Clicked",
                  properties: {
                    title: "Terms of Service",
                  },
                },
              ]}
            >
              Terms of Service
            </Link>

            <Link
              href="/company/p/cookie-policy"
              className="block gn-text-xs text-white hover:text-yellow px-sm py-xs md:py-0"
              events={[
                {
                  event: "Link Clicked",
                  properties: {
                    title: "Cookie Policy",
                  },
                },
              ]}
            >
              Cookie Policy
            </Link>

            <button
              type="button"
              onClick={() => {
                if (typeof window !== "undefined" && window.OneTrust) {
                  window.OneTrust.ToggleInfoDisplay();
                }
              }}
              className="block gn-text-xs text-white hover:text-yellow px-sm py-xs md:py-0"
            >
              Cookie Preferences
            </button>

            <Link
              href="/company/p/accessibility-statement"
              className="block gn-text-xs text-white hover:text-yellow px-sm py-xs md:py-0"
              events={[
                {
                  event: "Link Clicked",
                  properties: {
                    title: "Accessibility Statement",
                  },
                },
              ]}
            >
              Accessibility Statement
            </Link>
          </div>
          <p className="gn-text-xs text-white md:order-first my-sm md:my-0">
            &copy; {new Date().getFullYear()} GoNoodle, Inc.
          </p>
        </div>
      </div>
    </footer>
  );
}
