"use client";

import React from "react";
import dynamic from "next/dynamic";

// Dynamically import the LottiePlayer component
// Note: When using `next/dynamic`, the `ref` by default points to the LoadableComponent
// created by `next/dynamic` and NOT the underlying component (LottiePlayerLight).
// To work around this, we explicitly pass the `ref` via a different prop (`forwardedRef`).
const LazyLottiePlayer = dynamic(
  () =>
    import("react-lottie-player/dist/LottiePlayerLight").then((mod) => {
      const Component = mod.default;

      // Wrap the dynamically imported component in a functional component
      // This allows us to handle the `forwardedRef` explicitly and pass it to the `ref` of the actual LottiePlayerLight component.
      return function LottiePlayerWrapper({ forwardedRef, ...delegated }) {
        // The `forwardedRef` prop is mapped to the `ref` of the actual component
        return <Component {...delegated} ref={forwardedRef} />;
      };
    }),
  { ssr: false }, // Disable server-side rendering for this component
);

// Outer component to forward the `ref` from the parent
// Using `React.forwardRef`, the `ref` is forwarded and renamed to `forwardedRef`
// This renaming is necessary because the `ref` directly on LazyLottiePlayer points to the LoadableComponent.
export default React.forwardRef(function LottiePlayer(
  { children, ...delegated },
  forwardedRef,
) {
  return (
    // Pass the `forwardedRef` explicitly to the dynamically imported component
    // Without this explicit mapping, the `ref` would point to the LoadableComponent and not the actual LottiePlayerLight instance.
    <LazyLottiePlayer {...delegated} forwardedRef={forwardedRef}>
      {children}
    </LazyLottiePlayer>
  );
});
